<template>
  <div class="about section-vh">
    <b-container>
      <b-row>
        <b-col class="col my-5">
          <div class="text-left text-white" v-if="$i18n.locale === 'ru'">
            <div class="h5 text-left nba-text-color border_title pb-4 mb-4">Система проведения соревнований – регулярные Head-2-Head Liga - турниры по интерактивному баскетболу</div>
            <p>
              <b class="text-left nba-text-color">Продолжительность турнира составляет не более одного дня.</b>
            </p>
            <p>
              Турнир состоит из 6 встреч: 6 встреч группового этапа, круговая система (round-robin) в один круг. Если матч в основное время закончился ничейным результатом, то игра переводится в овертайм. Количество участников турнира – 4 человека.
            </p>
            <p>
              <b class="text-left nba-text-color">Турнир проводится в один этап:</b><br>
            </p>
            <p>
              Групповой этап. Встречи группового этапа проводятся до выявления победителя, с овертаймом 3 минуты игрового времени, если в основное время встреча закончилась вничью. Если первый овертайм сыгран вничью, то играется следующий до выявления победителя.
            </p>
            <p>
              Все участники играют в один круг между собой (у каждого из участников по 3 встречи), всего 6 встреч в группе. Победитель выявляется на основании лучших показателей – набранных очков.
            </p>
            <p>
              В ходе проведения группового этапа турнира команды набирают игровые очки, которые по итогам каждой встречи турнира складываются. Итоговое место участника в групповом этапе определяется по сумме набранных очков.
            </p>
            <p>
              За победу во встрече участнику начисляются 2 очка, проигравшему – 1 очко.
            </p>
            <p>
              В случае если одинаковое количество очков было набрано двумя участниками, победитель определяется на основании результатов их личной встречи.
            </p>
            <p>
              <b class="text-left nba-text-color">В случае если одинаковое количество очков было набрано тремя и более участниками, победитель определяется согласно правилам, указанным ниже (по&nbsp;ранжированию):</b>
            </p>
            <p>
              - по разнице заброшенных и пропущенных мячей в играх между собой;<br>
              - по большему количеству забитых мячей во встречах между собой.
            </p>
            <p>
              По окончании группового этапа производится подсчет очков, по итогам которого выявляется победитель турнира.
            </p>
          </div>

          <div class="text-left text-white" v-else>
            <div class="h5 text-left nba-text-color border_title pb-4 mb-4">Competition system: regular Head-2-Head Liga interactive basketball tournaments</div>
            <p>
              <b class="text-left nba-text-color">Tournament duration is no longer than one day.</b>
            </p>
            <p>
              The tournament consists of 6 matches: 6 group stage matches, a one-group round-robin. If a match ends in a draw in regular time, the game will go into overtime. Number of tournament participants: 4 people.
            </p>
            <p>
              <b class="text-left nba-text-color">The tournament is held in one stage:</b><br>
            </p>
            <p>
              Group stage. Group stage matches are held until a winner is determined, with an overtime of 3 minutes if the match ended in a draw in regular time. If the first overtime is tied, then the next one is played until the winner is determined.
            </p>
            <p>
              All participants play each other in one group (each participant has 3 meetings), with a total of 6 meetings in a group. The winner is determined on the basis of the number of points scored.
            </p>
            <p>
              During the group stage of the tournament, the teams earn game points that are added up at the end of each tournament match. The final place of the participant in the group stage is determined by the sum of points earned.
            </p>
            <p>
              A participant is awarded 2 points for winning a match and 1 point for the losing player.
            </p>
            <p>
              If the same number of points was earned by two participants, the winner is determined based on the results of their H2H match.
            </p>
            <p>
              <b class="text-left nba-text-color">In the event that the same number of points was earned by three or more participants, the winner is determined based on the rules indicated below (by&nbsp;ranking):</b>
            </p>
            <p>
              - by the difference between goals scored and conceded in games against each other;<br>
              - by who scores more goals in meetings against each other.
            </p>
            <p>
              At the end of the group stage, points are calculated and the winner of the tournament is announced.
            </p>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "ebasketball_rules",
  data() {
    return {}
  },
  metaInfo () {
    return {
      title: this.$t('meta.sports.nba.rules.title'),
      titleTemplate: 'H2H Liga | %s',
      meta: [
        {name: 'description', content: this.$t('meta.sports.nba.rules.description')},
      ]
    }
  }
}
</script>

<style scoped>
.border_title {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
</style>